import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Icon } from '@plone/volto/components';
import upArrow from '@package/icons/up.svg';
import downArrow from '@package/icons/down.svg';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
} from '@tanstack/react-table';
import { Input, Button } from 'semantic-ui-react';
import Select from 'react-select';
import { setBatchingParameters } from '../../actions';
import { UniversalLink } from '@plone/volto/components';

export const BasicTable = ({
  tableConfig,
  setTableConfig,
  batchingParameters,
  searchableFields = [],
}) => {
  const dispatch = useDispatch();
  const columns = useMemo(() => tableConfig.COLUMNS, [tableConfig.COLUMNS]);
  const nonLinkRows = useMemo(() => tableConfig.showDeleted, [
    tableConfig.showDeleted,
  ]);
  const [sortConfig, setSortConfig] = useState(() => {
    if (batchingParameters.sort_by) {
      return {
        key: batchingParameters.sort_by,
        direction: batchingParameters.asc ? 'asc' : 'desc',
      };
    }
    return null;
  });
  const itemsPerPageOptions = [10, 15, 30, 50, 100];
  const [localSearchTerms, setLocalSearchTerms] = useState(
    batchingParameters?.searchTerms.replaceAll(',', ' ') || '', // Initialize with search terms from batchingParameters
  );
  const [searchField, setSearchField] = useState(searchableFields.default);

  const history = useHistory();

  // unpack batchingParameters
  const limit = batchingParameters.limit;
  const totalPages = tableConfig.totalPages;
  const currentUrl = history.location.pathname + history.location.search;

  // reset search field when showDeleted is toggled
  useEffect(() => {
    setLocalSearchTerms(
      batchingParameters.searchTerms.replaceAll(',', ' ') || '',
    );
    setSortConfig({
      key: batchingParameters.sort_by,
      direction: batchingParameters.asc ? 'asc' : 'desc',
    });
  }, [
    tableConfig.showDeleted,
    batchingParameters.searchTerms,
    batchingParameters.sort_by,
    batchingParameters.asc,
  ]);

  const handleSort = (columnId) => {
    let direction = 'asc';
    if (
      sortConfig &&
      sortConfig.key === columnId &&
      sortConfig.direction === 'asc'
    ) {
      direction = 'desc';
    }
    setSortConfig({ key: columnId, direction });

    // reset Batching parameters when searching
    dispatch(
      setBatchingParameters({
        ...batchingParameters,
        skip: 0,
        sort_by: columnId,
        asc: direction === 'asc',
        currentPage: 0,
      }),
    );
  };

  const sortedData = useMemo(() => {
    if (sortConfig) {
      const sorted = [...tableConfig.data].sort((a, b) => {
        if (sortConfig.key === 'status' || sortConfig.key === 'rezeptstatus') {
          // Special sorting logic for the "status" & "rezeptstatus" column (1 = aktuell, 3 = in Bearbeitung , 2 = stillgelegt)
          const statusOrder = { 0: 0, 1: 1, 3: 2, 2: 3 };
          const orderFactor = sortConfig.direction === 'asc' ? 1 : -1;
          return (
            (statusOrder[a[sortConfig.key]] - statusOrder[b[sortConfig.key]]) *
            orderFactor
          );
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
          return 0;
        }
      });
      return sorted;
    }
    return tableConfig.data;
    // eslint-disable-next-line
  }, [
    tableConfig.data,
    sortConfig,
    batchingParameters.sort_by,
    batchingParameters.asc,
  ]);

  const table = useReactTable({
    columns,
    data: sortedData,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleSearch = () => {
    const searchTermsString = localSearchTerms.replaceAll(' ', ',');
    dispatch(
      setBatchingParameters({
        ...batchingParameters,
        sort_by: '',
        skip: 0,
        searchTerms: searchTermsString,
        searchField: searchField,
        currentPage: 0,
      }),
    );
    setSortConfig(null);
  };
  return (
    <>
      <div className="table-control">
        {batchingParameters.searchTerms !== undefined && setTableConfig ? (
          <form
            onSubmit={(e) => {
              e.preventDefault(); // Prevent the form from submitting and reloading the page
              handleSearch(); // Call the handleSearch function
            }}
          >
            <div className="search-container">
              <Input
                type="text"
                placeholder="Search..."
                value={localSearchTerms}
                onChange={(e) => setLocalSearchTerms(e.target.value)}
              />
              {searchableFields?.fields?.length > 0 && (
                <Select
                  className="search-field-dropdown"
                  placeholder="In Feld Suchen"
                  options={searchableFields?.fields}
                  onChange={(selection) => setSearchField(selection)}
                  value={searchField || []}
                />
              )}
              <Button type="submit">Search</Button>{' '}
            </div>
          </form>
        ) : null}
      </div>
      <div className="rdb-table-container">
        <table className="rdb-table">
          <thead className="rdb-header">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className="header-row">
                {headerGroup.headers.map((header) => (
                  <th
                    className="header-cell"
                    key={header.id}
                    onClick={() => handleSort(header.id)}
                  >
                    <div>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                      {!sortConfig && (
                        <div className="sorting-arrows">
                          <Icon name={upArrow} />
                          /<Icon name={downArrow} />
                        </div>
                      )}
                      {sortConfig && sortConfig.key !== header.id && (
                        <div className="sorting-arrows">
                          <Icon name={upArrow} />
                          /<Icon name={downArrow} />
                        </div>
                      )}
                      {sortConfig && sortConfig.key === header.id && (
                        <p>
                          {sortConfig.direction === 'asc' ? (
                            <div className="sorting-arrows">
                              <Icon name={downArrow} />
                            </div>
                          ) : (
                            <div className="sorting-arrows">
                              <Icon name={upArrow} />
                            </div>
                          )}
                        </p>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="rdb-body">
            {table.getRowModel().rows.map((row) => (
              <tr className="body-row" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td className="body-cell" key={cell.id}>
                    <UniversalLink
                      href={`${currentUrl}/${
                        tableConfig.linkToEdit ? 'edit' : 'details'
                      }?id=${row.original.id}`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </UniversalLink>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {tableConfig.totalPages > 1 && (
        <div className="listing-footer">
          <div className="dummyCell" />
          <div className="pagination-container">
            <span>Seiten</span>
            <div className="pagination">
              {batchingParameters.currentPage > 0 && (
                <Button
                  primary
                  type="previous"
                  onClick={() => {
                    const newPage = batchingParameters.currentPage - 1;
                    dispatch(
                      setBatchingParameters({
                        ...batchingParameters,
                        currentPage: newPage,
                        skip: newPage * limit,
                      }),
                    );
                  }}
                  className="arrow"
                >
                  &lt; zurück
                </Button>
              )}
              <span
                onClick={() => {
                  const newPage = batchingParameters.currentPage - 1;
                  dispatch(
                    setBatchingParameters({
                      ...batchingParameters,
                      currentPage: newPage,
                      skip: newPage * limit,
                    }),
                  );
                }}
                disabled={batchingParameters.currentPage === 0}
                className={batchingParameters.currentPage === 0 ? 'active' : ''}
              >
                1
              </span>
              {batchingParameters.currentPage > 2 && (
                <span className="ellipsis">...</span>
              )}
              {Array.from({ length: totalPages }, (_, index) => index).map(
                (page) => {
                  if (page === 0 || page === totalPages - 1) {
                    return null;
                  }
                  if (
                    page === batchingParameters.currentPage - 2 ||
                    page === batchingParameters.currentPage - 1 ||
                    page === batchingParameters.currentPage ||
                    page === batchingParameters.currentPage + 1 ||
                    page === batchingParameters.currentPage + 2
                  ) {
                    return (
                      <span
                        key={page}
                        onClick={() => {
                          dispatch(
                            setBatchingParameters({
                              ...batchingParameters,
                              currentPage: page,
                              skip: page * limit,
                            }),
                          );
                        }}
                        className={
                          batchingParameters.currentPage === page
                            ? 'active'
                            : ''
                        }
                      >
                        {page + 1}
                      </span>
                    );
                  }
                  return null;
                },
              )}
              {batchingParameters.currentPage < totalPages - 3 && (
                <span className="ellipsis">...</span>
              )}
              <span
                onClick={() => {
                  const currentPage = totalPages - 1;
                  dispatch(
                    setBatchingParameters({
                      ...batchingParameters,
                      currentPage: currentPage,
                      skip: currentPage * limit,
                    }),
                  );
                }}
                disabled={batchingParameters.currentPage === totalPages - 1}
                className={
                  batchingParameters.currentPage === totalPages - 1
                    ? 'active'
                    : ''
                }
              >
                {totalPages}
              </span>
              {batchingParameters.currentPage < totalPages - 1 && (
                <Button
                  primary
                  type="next"
                  onClick={() => {
                    const newPage = batchingParameters.currentPage + 1;
                    dispatch(
                      setBatchingParameters({
                        ...batchingParameters,
                        currentPage: newPage,
                        skip: newPage * limit,
                      }),
                    );
                  }}
                  className="arrow"
                >
                  weiter &gt;
                </Button>
              )}
            </div>
          </div>
          <div className="items-per-page-dropdown">
            <span>Ergebnisse pro Seite :</span>
            <select
              value={limit}
              onChange={(e) => {
                dispatch(
                  setBatchingParameters({
                    ...batchingParameters,
                    limit: parseInt(e.target.value),
                    skip: 0,
                    currentPage: 0,
                  }),
                );
              }}
            >
              {itemsPerPageOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </>
  );
};
